:root {
  --header-bg_color: #022741;
  --header-color:#fff;
  --header-color_hover:  #7B7AC8;
  --balance-heading:#7b7ac8d7;
  --balance-value:#f4f8fb;
  --profile-dropdown_color: #000;
  --profile-dropdown_color_hover: #ffffff;
  --profile-dropdown_bg_hover: #01483791;
  --login-btn-color:#fff;
  --login-btn-bg:#7B7AC8;
  --heading_2-color: #001A16;
  --logoName-bgColor:linear-gradient(to right, #7B7AC8, #f7c248);
  --Home-page-bg: transparent linear-gradient(180deg, #FFFFFF 0%, #F7F4F8 100%) 0% 0% no-repeat padding-box;

  --tab-link-color: #613D3B;
  --tab-link-color-hover: #141414;
  --tab-link-bgColor: rgba(255, 255, 255, 0.06);
  --tab-link-bgColor-hover: #c8cced42;
  --banner-color: #fff;

  --tab-content-bgColor:  #D0D3EF;
  --tab-content-boxShadow: 0 1px 1px transparent;
  --tab-content-left-bgColor: transparent;
  --tab-content-input-color:#141414;
  --tab-content-input-border:1px solid rgba(0, 0, 0, 0.15);
  --tab-content-border: none;

  --footer-border-color: #03365a;
  --footer-border-link-color:#ffffff93;
  --footer-color:#ffffff93;
  --footer-contact-h2-color:#363636;
  --footer-img-shadow: 0px 1px 2px #00000036;
  --footer-socile-icon-bgColor: #03365a;
  --footer-socile-icon-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(255, 255, 255, 0.1) 0px 0px 1px 0px;
  --footer-contact-img-bgColor: #613d3b;
  

  --GrowYourBussionWrapper-bgColor: #fff;
  --GrowYourBussion-wrapper-img-bgColor:#f7ed31e7;
  --footer-conttant-box-bgColor: #fff;
  --footer-conttant-box-shadow: 0px 1px 20px #49494921;
  --unnamed-color-ffffff: #fff;

  --slick-active-color:#022741;
  --slick-dots-color: #fff;

  --section-gbColor-V1:#E6FF8294;
  --section-gbColor-V2: transparent linear-gradient(250deg, #C8CCED 0%, #FBD7DB 100%) 0% 0% no-repeat padding-box;

  --blue: #1e90ff;
  --white: #ffffff;
}

